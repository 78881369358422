import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import { Link } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { getProductUrl } from "../lib/helpers";

import * as styles from "../styles/components/product-card.module.css";

const ProductCard = (props) => {

  const {
    product,
    type
  } = props;

  return (
    <div className={styles.root}>
      {((product.featuredImage && product.featuredImage.asset) || (product.secondaryFeaturedImage && product.secondaryFeaturedImage.asset)) && (
        <div className={styles.image}>
          <Link className={cn(styles.link, product.secondaryFeaturedImage && product.secondaryFeaturedImage.asset && styles.hasHover)} to={getProductUrl(product.slug)} title={product.title}>

            {product.featuredImage && product.featuredImage.asset && (
              <span className={styles.topImage}>
                <img
                  src={imageUrlFor(buildImageObj(product.featuredImage))
                    .auto("format")
                    .url()}
                  alt={product.featuredImage.alt}
                />
              </span>
            )}

            {product.secondaryFeaturedImage && product.secondaryFeaturedImage.asset && (
              <span className={styles.bottomImage}>
                <img
                  src={imageUrlFor(buildImageObj(product.secondaryFeaturedImage))
                    .auto("format")
                    .url()}
                  alt={product.secondaryFeaturedImage.alt}
                />
              </span>
            )}
          </Link>
        </div>
      )}

      <div className={styles.meta}>
        {product.title && (<h4><Link to={getProductUrl(product.slug)}>{product.title}</Link></h4>)}
        {type === 'featured' && (
          <>
          {product.description && (<p>{product.description}</p>)}
          <Link to={getProductUrl(product.slug)} className="view--more">View</Link>
          </>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
