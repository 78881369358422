import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import ProductCard from "./product-card";

import * as styles from "../styles/components/products-list.module.css";

const ProductsList = ({ products }) => {

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          {products.map(({ node: product }) => (
            <div key={product.id} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default ProductsList;
