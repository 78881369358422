import React, { useState } from "react";
import Container from "./container";
import Taxonomy from "./taxonomy";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/product-filters.module.css";

const ProductFilters = (props) => {

  const {
    categories,
    tags,
    currentCategory,
    currentTag
  } = props;

  const [showOverlay, setShowOverlay] = useState(false);

  function handleShowOverlay(e) {
    setShowOverlay(true);
    e.target.parentElement.classList.add('active');
  }

  function handleHideOverlay() {
    setShowOverlay(false);
    const filters = document.querySelectorAll('.filter');
    Array.from(filters).forEach(function(el,i){
      el.classList.remove('active');
    });
  }

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          <div className="col-6 col-xl-2">
            {categories && (<Taxonomy items={categories} label="Type" term={currentCategory} anotherTerm={currentTag} showOverlay={showOverlay} onShowOverlay={handleShowOverlay} onHideOverlay={handleHideOverlay} />)}
          </div>
          <div className="col-6 col-xl-2">
            {tags && (<Taxonomy items={tags} label="Location" term={currentTag} anotherTerm={currentCategory} showOverlay={showOverlay} onShowOverlay={handleShowOverlay} onHideOverlay={handleHideOverlay} />)}
          </div>
        </div>
        <div className={cn(styles.overlay, showOverlay && styles.active)} onClick={handleHideOverlay}></div>
      </Container>
    </div>
  );
}

export default ProductFilters;
