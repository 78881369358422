import React from "react";
import { Link } from "gatsby";
import { getProductCatUrl, getProductTagUrl, cn } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/taxonomy.module.css";

const Taxonomy = (props) => {

  const {
    items,
    label,
    term,
    anotherTerm,
    showOverlay,
    onShowOverlay,
    onHideOverlay
  } = props;

  return (
    <div className={cn(styles.root, "filter")}>
      {label && (<h5 className={styles.label}>{label}</h5>)}
      <span className={cn(styles.currentTerm, ((!term && !anotherTerm) || term) ? styles.selected : undefined)} onClick={showOverlay ? onHideOverlay : onShowOverlay}>{term ? term.title : "All"}</span>
      <ul className={styles.termsList}>
        {items.edges.map(({node: item}) => (
          <li key={item.id}>
            <Link to={label === "Type" ? getProductCatUrl(item.slug) : getProductTagUrl(item.slug)} onClick={onHideOverlay}>{item.title}</Link>
          </li>
        ))}
        <li>
          <Link to="/products" onClick={onHideOverlay}>All</Link>
        </li>
      </ul>
    </div>
  );
}

export default Taxonomy;
